import React, { useState, useEffect } from 'react'
import { Stack, Grid } from '@mui/material'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Product from '../components/Products/Product/Product.js'
import { getMiniProducts } from '../api/productApi.js'
import { CURRENT_USER_ID, USER_RESP_ID } from '../consts.js';
import pdf from "../images/EnergyGuide.pdf"
import PdfViewer from '../components/ProductDetail/PdfViewer/PdfViewer.js'

export default function MiniSurvey(props) {
    const boxVersion = JSON.parse(sessionStorage.getItem(CURRENT_USER_ID))?.version;
    const [products, setProducts] = React.useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [currentPdf, setCurrentPdf] = useState(pdf)
    const [currentProductHighlighted, setCurrentProductHighlighted] = React.useState('');

    React.useEffect(() => {
        getMiniProducts()
            .then(res => { setProducts(res) })
    }, [])

    function loadPdf(product) {
        let idx = 0
        if (boxVersion >= 1 && boxVersion <= 3) {
            idx = 0
        } else if (boxVersion === 4) {
            idx = 1
        } else {
            idx = 2
        }

        const newPdf = product.energyGuides[idx] ? (`${process.env.REACT_APP_API_URL}/image/${product.energyGuides[idx].fileName}`) : pdf
        setCurrentPdf(newPdf)
    }

    function closePdf() {
        setShowPdf(false)
    }

    function prepareProducts(products) {
        return (products.map(product => <Grid item
                xs={12} sm={6} md={4} xl={4}
                key={product._id}
                style={{ paddingBottom: '15px', minWidth: '50px' }}>
                    <Product key={product._id}
                        data={product}
                        inCart={false}
                        addToCompare={()=>{}}
                        removeFromCompare={()=>{}}
                        toggleDrawer={()=>{}}
                        triggerPdf={() => { loadPdf(product); setCurrentProductHighlighted(product._id); setShowPdf(true) }}
                        activityTrigger={()=> {}}
                        silent={true}
                    />
            </Grid>))
    }

    return (<>
        <Header />
        <>
            <PdfViewer pdf={currentPdf}
                onCancel={() => closePdf()}
                onClose={() => closePdf()}
                visible={showPdf}
            />
            <Grid container spacing={2} sx={{ paddingTop: '25px', paddingLeft: { xs: '25px', sm: '75px', md: '100px', xl: '200px' }, paddingRight: { xs: '25px', sm: '75px', md: '100px', xl: '200px' } }} >
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {prepareProducts(products)}
                    </Grid>
                </Grid>
            </Grid>
        </>
    </>)
}